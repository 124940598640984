// material-ui
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button
} from '@mui/material';

import FadeInWhenVisible from 'utils/Animation';

import { motion } from 'framer-motion';

import generalCourses from 'assets/images/landing/guiding/general-courses.png';
import handicapCourses from 'assets/images/landing/guiding/handicap-courses.png';
import additionalCourses from 'assets/images/landing/guiding/additional-courses.png';

import audioChapters from 'assets/images/landing/guiding/audio-chapters.png';
import chapter from 'assets/images/landing/guiding/chapter.png';

import quiz1 from 'assets/images/landing/guiding/quiz1.png';
import quiz2 from 'assets/images/landing/guiding/quiz2.png';
import quiz3 from 'assets/images/landing/guiding/quiz3.png';
import quiz4 from 'assets/images/landing/guiding/quiz4.png';

import certificate from 'assets/images/landing/guiding/certificate.png';
import { KeyedObject } from 'types';
import { useRef, useEffect } from 'react';

// ===========================|| LANDING - CUSTOMIZE ||====================== //

interface GuidingSectionProps extends KeyedObject {
  setShowFab: (arg0: boolean) => void;
}

const GuidingSection = ({ setShowFab }: GuidingSectionProps) => {
  const theme = useTheme();
  const headerRef = useRef(null);
  const certificateRef = useRef(null);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const current = certificateRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowFab(true);
        }
      },
      {
        threshold: 0.1
      }
    );

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [setShowFab]);

  useEffect(() => {
    const current = headerRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowFab(false);
        }
      },
      {
        threshold: 0.1
      }
    );

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [setShowFab]);

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 4
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={{ xs: 1.5, sm: 2.5, md: 3, lg: 5 }}
      >
        <Grid item xs={12} sx={{ mb: { xs: 4, md: 0 } }}>
          <FadeInWhenVisible>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="h1"
                color="primary"
                textAlign="center"
                sx={{
                  mb: 4,
                  maxWidth: '37ch'
                }}
                ref={headerRef}
              >
                Entwickelt von einem Team aus ExpertInnen und
                WissenschaftlerInnen aus den Bereichen inklusive Pädagogik,
                Psychologie und Psychiatrie.
              </Typography>
            </Box>
          </FadeInWhenVisible>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                22+ interaktive Fortbildungskurse
              </Typography>
              <Typography
                sx={{
                  zIndex: '99',
                  width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' },
                  mb: 4,
                  maxWidth: '44ch'
                }}
              >
                Unser digitales Kursangebot wurde in Kooperation mit der
                Universität Regensburg speziell für das Berufsbild
                Schulbegleitung entwickelt.
                <Button
                  sx={{ my: 3 }}
                  href="/kursplankompakt-juni.pdf"
                  target="_blank"
                  variant="contained"
                >
                  Unser Curriculum
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: 24 }}>
            {/* general */}
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 0, sm: 16, lg: 24 },
                right: { xs: 180, sm: 280, md: 240, lg: 320 },
                width: { xs: 160, lg: 180 }
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <Typography variant="h4" color="primary">
                    3 Einarbeitungskurse
                  </Typography>
                  <img
                    src={generalCourses}
                    alt="Stack of 3 cards representing the general courses"
                  />
                </Stack>
              </motion.div>
            </Box>
            {/* handicap */}
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 104, sm: 120, lg: 96 },
                right: { xs: 56, sm: 150, md: 80, lg: 132 },
                width: 220
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box width={isLargeScreen ? 220 : 150}>
                    <img
                      src={handicapCourses}
                      alt="Stack of 8 cards representing handicap courses"
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    color="primary"
                    width="40ch"
                    textAlign="center"
                  >
                    11 Kurse zu seelischen, körperlichen und geistigen
                    Behinderungen <br /> (nach SGB VIII und IX)
                  </Typography>
                </Box>
              </motion.div>
            </Box>
            {/* additional */}
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 0, sm: 16, lg: 24 },
                right: { xs: 0, sm: 100, md: 0 },
                width: { xs: 160, lg: 180 }
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <Typography variant="h4" color="primary">
                    8+ Zusatzkurse
                  </Typography>
                  <img
                    src={additionalCourses}
                    aria-label="Stack of 5 cards representing additional courses"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {!isLargeScreen && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Alle Kurs-Inhalte auch als Hörversion
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      pb: 2,
                      maxWidth: '44ch'
                    }}
                  >
                    Über 1.000 Seiten Lernstoff und 30 Stunden Hörmaterial. Für
                    ein schnelles und einfaches Verständnis sowie mehr
                    Lernerfolge.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: { xs: 18, md: 24 } }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 10, md: 32 },
                left: { xs: 100, sm: 200, md: 120, lg: 120 },
                width: { xs: 160, lg: 240 },
                animation: '8s slideY linear infinite',
                zIndex: 3
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={audioChapters}
                    alt="Hovering card showing multiple chapters and an audio player"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 10, md: 24 },
                left: { xs: 0, sm: 100, md: 0 },
                width: { xs: 336, md: 400, lg: 480 },
                zIndex: 2
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={chapter}
                    alt="Card showing an example chapter of the platforms content"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {isLargeScreen && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Alle Kurs-Inhalte auch als Hörversion
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      textAlign: 'end',
                      maxWidth: '40ch'
                    }}
                  >
                    Über 1.000 Seiten Lernstoff und 30 Stunden Hörmaterial. Für
                    ein schnelles und einfaches Verständnis sowie mehr
                    Lernerfolge.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                Regelmäßige Lernerfolgskontrollen
              </Typography>
              <Typography
                sx={{
                  zIndex: '99',
                  width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' },
                  maxWidth: '36ch',
                  pb: 2
                }}
              >
                In jedem Kapitel wird das neu erlernte Wissen durch
                Multiple-Choice-Aufgaben und Transfer-Übungen vertieft.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{ img: { width: '100%' } }}>
          <Box sx={{ position: 'relative', py: { xs: 24, lg: 22 } }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 0, md: 64, lg: 0 },
                right: { xs: 0, sm: 100, md: 0 },
                width: { xs: 320, lg: 320 },
                zIndex: 5
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={quiz1}
                    aria-label="First Card of a stack representing quiz Questions"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 32, md: 96, lg: 32 },
                right: { xs: 0, sm: 100, md: 0 },
                width: { xs: 320, lg: 320 },
                zIndex: 4
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={quiz2}
                    alt="Second Card of a stack representing quiz Questions"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 64, md: 128, lg: 64 },
                right: { xs: 0, sm: 100, md: 0 },
                width: { xs: 320, lg: 320 },
                zIndex: 3
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={quiz3}
                    alt="Third Card of a stack representing quiz Questions"
                  />
                </Stack>
              </motion.div>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 96, md: 160, lg: 96 },
                right: { xs: 0, sm: 100, md: 0 },
                width: { xs: 320, lg: 320 },
                zIndex: 2
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={quiz4}
                    alt="Fourth Card of a stack representing quiz Questions"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {!isLargeScreen && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Zertifikate nach jedem Kursabschluss
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      pb: 2,
                      maxWidth: '44ch'
                    }}
                  >
                    Für einen höheren pädagogischen Qualitätsstandard und mehr
                    Transparenz gegenüber Ämtern.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ img: { width: '100%' } }}
          ref={certificateRef}
        >
          <Box sx={{ position: 'relative', py: { xs: 12, lg: 20 } }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: 26, sm: 32, lg: 32 },
                left: { xs: 0, sm: 100, md: 0 },
                width: { xs: 336, md: 440, lg: 480 },
                zIndex: 2
              }}
            >
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, margin: '0px 0px -120px 0px' }}
                transition={{
                  type: 'spring',
                  stiffness: 150,
                  damping: 30
                }}
              >
                <Stack textAlign="center" spacing={2}>
                  <img
                    src={certificate}
                    alt="Card that shows an example certificate"
                  />
                </Stack>
              </motion.div>
            </Box>
          </Box>
        </Grid>
        {isLargeScreen && (
          <Grid item xs={12} md={6} ref={certificateRef}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} textAlign="end">
                <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
                  Zertifikate nach jedem Kursabschluss
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="end">
                  <Typography
                    sx={{
                      zIndex: '99',
                      maxWidth: '44ch'
                    }}
                  >
                    Für einen höheren pädagogischen Qualitätsstandard und mehr
                    Transparenz gegenüber Ämtern.
                  </Typography>
                </Box>
                {/* <Button
                  onClick={() => {
                    scroller.scrollTo('salesContainer', {
                      duration: 4000,
                      smooth: true
                    });
                  }}
                  variant="contained"
                  sx={{ textTransform: 'none', fontSize: '1.2rem', mt: 16 }}
                  size="large"
                >
                  Kostenlos testen
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
export default GuidingSection;
